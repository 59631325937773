<template>
  <div class="merchcode">
    <van-nav-bar :title="showqrText" left-arrow @click-left="onClickLeft" />
    <div id="capture" style="overflow: hidden;">
      <div id="qrcode" :style="'padding:' + (screenWidth * 0.1) + 'px;'"></div>
      <van-image
        id="avatarimg"
        crossorigin="anonymous"
        :style="'margin-top:-' + ((screenWidth / 2) + 36) +'px;margin-left:-36px;border: 6px solid #fff; border-radius: 2px;'"
        :src="isLogin && userinfo.avatar ? userinfo.avatar : avatar"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </div>
    <div
      id="shwo"
      :style="'width:' + screenWidth + 'px;height: ' + screenWidth + 'px;text-align: center;display:none;'"
    >
      <img id="img" crossorigin="anonymous" style="margin: 0 auto;padding: 0;" />
    </div>
  </div>
</template>
<script>
export default {
  name: "MerchCode",
  data() {
    return {
      avatar: require("../assets/head.png"), // 默认用户头像
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      userinfo: {},
      showqrText: "店铺二维码", // 二维码的名称
      qrcodeImg: "", // 默认没有二维码
      isLogin: localStorage.getItem("DK_ISLOGIN")
        ? localStorage.getItem("DK_ISLOGIN")
        : false,
      shareid: 0,
      merchid: 0
    };
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.$parent.getmerchname(this.merchid, this.showqrText);
    this.init();
  },
  methods: {
    init() {
      // 初始化方法
      let _that = null;
      _that = this;

      const jwtStr = localStorage.getItem("DK_UID")
        ? localStorage.getItem("DK_UID")
        : ""; // 公钥
      _that.$axios.defaults.headers.common["Authorization"] = jwtStr;
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getinfor")
        .then(function(response) {
          console.log(response);
          if (_that.isLogin && response.data) {
            _that.userinfo = response.data;
            _that.showqrcode();
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    showqrcode() {
      let _that = null;
      _that = this;
      _that.$vueqrcode;
      // 二维码链接
      _that.qrcodeImg = new _that.QRCode(document.getElementById("qrcode"), {
        width: _that.screenWidth * 0.8,
        height: _that.screenWidth * 0.8,
        text: _that.$store.state.domain3 + "?shareid=" + _that.userinfo.id + "&merchid=" + _that.userinfo.merchid, // 二维码地址
        colorDark: "#000000",
        colorLight: "#ffffff"
      });

      setTimeout(() => {
        const el = document.getElementById("capture"); //要截图的div  document.querySelector("#capture")

        _that
          .$html2canvas(el, {
            useCORS: true
          })
          .then(canvas => {
            // 生成图片
            // document.body.appendChild(canvas)
            document.getElementById("capture").style.display = "none";
            document.getElementById("shwo").style.display = "block";
            const tempSrc = canvas.toDataURL("image/png");
            console.log(tempSrc);
            document.getElementById("img").src = tempSrc;
          });
      }, 1000);
    },
    onClickLeft() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="less">
.merchcode {
  height: 100%;
  width: 100%;
  position: relative;
  background: #ededed;
  z-index: 2;
  .van-nav-bar__left {
    .van-icon {
      color: #333;
    }
  }
  #capture {
    overflow: hidden;
    position: relative;
    #qrcode {
      overflow: hidden;
      background-color: #fff;
    }
    #avatarimg {
      width: 60px;
      height: 60px;
      position: absolute;
    }
  }

  #shwo {
    overflow: hidden;
    #img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
